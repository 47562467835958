<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style scoped>
  .check-distri label {
    margin-bottom: 0;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  .inputDeep>>>.el-input__inner {
    border: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getPost,
    editPost,
    deletePost
  } from "@/api/zhengshu/send.js"
  /**
   *证书邮寄管理
   */
  export default {
    components: {
      Layout,
      CheckHeader,



    },
    data() {
      return {
        title: " 证书邮寄管理 /   ",
        title2: "",
        subTitle: "",
        exportTitle: "证书邮寄导出",
        module: "ZSYJ",
        moduleUpdate: "ZSYJGX",
        showBatch: false,
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "证书邮寄",
            active: true
          }
        ],
        showModal: false,
        tableList: [],
        queryType: [{
            name: "考试名称",
            val: "ksmc",
          },
          {
            name: "姓名",
            val: "xm",
          },
          {
            name: "证件号",
            val: "zjhm",
          }, {
            name: "订单号",
            val: "ddh",
          },
          {
            name: "运单号",
            val: "ydh",
          }
        ],
        statusLsit: [{
            name: "未支付",
            val: -1,
          }, {
            name: "待揽件",
            val: 0,
          },
          {
            name: "已发件",
            val: 1,
          },
          {
            name: "已签收",
            val: 2,
          }, {
            name: "已退件",
            val: 9,
          },
        ],
        addForm: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          keyword: "",
          keyParam: "",
          yjzt:0,
          is_delete: "0",
        },
        imTitle: "证书邮寄物流单号更新导入",
        eidtOldYjzt: "",
      };
    },
    methods: {
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      getList() {
        getPost(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletePost(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showModal = true
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      // 提交
      submit() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        editPost(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message ? res.message : '数据操作成功!',
            })
            this.showModal = false
            this.getList()
          }
        })
      },
      // 修改物流单号
      editWldh(ele) {
        editPost({
          sid: ele.sid,
          ydh: ele.ydh
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message ? res.message : '数据操作成功!',
            })
            this.showModal = false
            this.getList()
          }
        })
      },
      setOld(obj) {
        this.eidtOldYjzt = obj.yjzt;
      },
      // 修改邮寄状态
      edityjzt(ele) {
        this.$confirm('确认修改当前信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          editPost({
            sid: ele.sid,
            yjzt: ele.yjzt
          }).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: res.message ? res.message : '数据操作成功!',
              })
            }
          })
        }).catch(() => {
          ele.yjzt = this.eidtOldYjzt;
        })
      },
      uploadFile() {
        var _this = this;
        _this.$importModal().show({
          title: this.imTitle,
          module: this.moduleUpdate,
          success: this.success
        })
      },
      success() {
        this.getList()
      },
      cutout(cellValue) {
        return cellValue.replace(/,/g, '</br>')
      },
      ddtop(obj) {
        return `
        订单金额：${obj.zfje/100}元<br>
        订单状态：${obj.zfzt===1?'已支付':obj.zfzt===9?'已退款':'未支付'}<br>
        订单编号：${obj.ddh==null?"":obj.ddh}<br>
        支付时间：${obj.zfsj==null?"":obj.zfsj}
        `;
      }
    },
    mounted() {
      this.getList()
    },
    created() {

    },
    watch: {
      'pageData.keyParam': {
        handler(value) {
          this.pageData.keyword = ''
        }
      }
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :subTitle="subTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="查询类别" size="small"
                  v-model="pageData.keyParam">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in queryType" :key="i">{{item.name}}
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 form-control border-blue mr-2" maxlength="50" style="width: 10%;"
                  v-model="pageData.keyword" />

                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="邮寄状态" size="small"
                  v-model="pageData.yjzt">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in statusLsit" :key="i">{{item.name}}
                  </el-option>
                </el-select>

                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="uploadFile"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入更新物流单号</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 5%;">姓名</th>
                    <th style="width: 10%;">证件号码</th>
                    <th style="width: 10%;">考试信息</th>
                    <th style="width: 6%;">专业</th>
                    <th style="width: 5%;">级别</th>
                    <th style="width: 10%;">证书号</th>
                    <th style="width: 8%;">移动电话</th>
                    <th style="width: 5%;">地址</th>
                    <th style="width: 5%;">邮编</th>
                    <th style="width: 3%;">订单</th>
                    <th style="width: 5%;">支付</th>
                    <th style="width: 5%;">物流</th>
                    <th style="width: 6%;;">运单号</th>
                    <th style="width: 10%;" class="text-center">邮寄状态</th>
                    <th style="width: 4%;" class="text-center">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.xm}} </td>
                    <td>{{obj.sfzh}} </td>
                    <td>{{obj.ksxx}}</td>
                    <td>{{obj.zy}}</td>
                    <td>{{obj.jb}}</td>
                    <td v-html="cutout(obj.zsh)"></td>
                    <td>{{obj.yddh}}</td>
                    <td>
                      <el-tooltip class="item" :content="obj.dz" placement="top">
                        <a>地址</a>
                      </el-tooltip>
                    </td>
                    <td>{{obj.yb}}</td>
                    <td>
                      <el-tooltip class="item" placement="top">
                        <div slot="content" v-html="ddtop(obj)"> </div>
                        <a>订单</a>
                      </el-tooltip>
                    </td>
                    <td>
                      <div class="line1 text-danger" v-if="obj.zfzt=='0'" title="未支付">未支付</div>
                      <div class="line1 text-success" v-if="obj.zfzt=='1'" title="已支付">已支付</div>
                      <div class="line1 text-warning" v-if="obj.zfzt=='9'" title="已退款">已退款</div>
                    </td>
                    <td>{{obj.wlgs}}</td>
                    <td>
                      <el-tooltip v-if="obj.ydh!=null" class="item" :content="obj.ydh" placement="top">
                        <a>运单号</a>
                      </el-tooltip>
                    </td>
                    <td class="inputDeep">
                      <el-select placeholder="邮寄状态" size="mini" v-model="obj.yjzt" @click.native="setOld(obj)"
                        @change="edityjzt(obj)">
                        <el-option :value="item.val" :label="item.name" v-for="(item,i) in statusLsit" :key="i">
                          {{item.name}}
                        </el-option>
                      </el-select>
                    </td>
                    <td class="tab-icon ">
                      <i class="iconfont icon-riLine-delete-bin-line" v-if="obj.zfzt!='1'"
                        @click="deleteItem(obj.sid,obj.zsh)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 切换库 -->
    <!-- 编辑 -->
    <!-- 批量处理 -->
    <!-- 弹窗结束 -->
  </Layout>
</template>
