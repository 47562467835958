// 证书邮寄
import {
  getRequest,
  // postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 分页查询
export const getPost = params => {
  return getRequest("/yethan/cert/logisticsPost/listPage", params);
};

// 修改
export const editPost = params => {
  return putRequest("/yethan/cert/logisticsPost", params);
};
// 删除
export function deletePost(sid) {
  return deleteRequest("/yethan/cert/logisticsPost/" + sid);
}